import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./project.css";
import { motion } from "framer-motion";

export default function Project({ children, linkTo, title }) {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Wrapper>
      <Link
        to="/page-2/"
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
      >
        {children}
        <ProjectOverlay
          initial={{ opacity: 0 }}
          transition={{ type: "spring" }}
          animate={{ opacity: showOverlay ? 1 : 0 }}
        >
          <ProjectTitle>{title}</ProjectTitle>
        </ProjectOverlay>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: black;
  display: grid;
  position: relative;
`;

const ProjectOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 255, 0.75);
  background: linear-gradient(rgba(0, 0, 255, 0.75), #e66465);
  font-size: 3.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  color: white;
`;

const ProjectTitle = styled.p`
  font-size: 32px;
`;
