import { Link, useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Project from "./project";
import Masonry from "react-masonry-css";
import "./Projects.css";
import Image from "../components/image";
import Img from "gatsby-image";

export default function Projects() {
  const data = useStaticQuery(graphql`
    query {
      mc: file(relativePath: { eq: "mc.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quill: file(relativePath: { eq: "quill.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      probable: file(relativePath: { eq: "probable.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mechanisms: file(relativePath: { eq: "mechanisms.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collage: file(relativePath: { eq: "collage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inorbit: file(relativePath: { eq: "inorbit.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marilyn: file(relativePath: { eq: "marilyn.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sonicsketches: file(relativePath: { eq: "sonicsketches.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      formations: file(relativePath: { eq: "formations.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bitrot: file(relativePath: { eq: "bitrot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Masonry
        breakpointCols={2}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Project title="MC Series">
          <Img fluid={data.mc.childImageSharp.fluid} />
        </Project>

        <Project title="Improbable Inevitable">
          <Img fluid={data.probable.childImageSharp.fluid} />
        </Project>
        <Project title="Quill Series">
          <Img fluid={data.quill.childImageSharp.fluid} />
        </Project>
        <Project title="Mechanisms">
          <Img fluid={data.mechanisms.childImageSharp.fluid} />
        </Project>

        <Project title="St. Marilyn">
          <Img fluid={data.marilyn.childImageSharp.fluid} />
        </Project>
        <Project title="Collage">
          <Img fluid={data.collage.childImageSharp.fluid} />
        </Project>

        <Project title="Sonic Sketches">
          <Img fluid={data.sonicsketches.childImageSharp.fluid} />
        </Project>
        <Project title="Formations">
          <Img fluid={data.formations.childImageSharp.fluid} />
        </Project>
        <Project title="In Orbit">
          <Img fluid={data.inorbit.childImageSharp.fluid} />
        </Project>
        <Project title="Bit Rot">
          <Img fluid={data.bitrot.childImageSharp.fluid} />
        </Project>
      </Masonry>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;

  @media only screen and (max-width: 480px) {
    padding-top: 40px;
  }
`;
